
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import Util from "../../../lib/util";
import Campana from "../../../store/entities/OZONE/campana";
import AbpBase from "../../../lib/abpbase";
import Participacion from "../../../store/entities/OZONE/participacion";
import ParticipacionDato from "../../../store/entities/OZONE/participacionDato";
import { Input } from "ant-design-vue";

class PageCamposRequest extends PageRequest {
  campanaId: number;
}

class PaticipacionDatoRequest extends PageRequest {
  participacionId: number;
}

@Component({
  components: {},
})

export default class Formularios extends AbpBase {
  labelCol = { span: 4 };
  wrapperCol = { span: 14 };

  campana: Campana = new Campana();
  campanaId: number = 0;
  participacion: Participacion = new Participacion();
  participacionDato: Array<ParticipacionDato> = [];
  rules: Array<any> = [];
  formFields = []
  pageCamposRequest: PageCamposRequest = new PageCamposRequest();
  pagePaticipacionDatoRequest: PaticipacionDatoRequest = new PaticipacionDatoRequest();
  loading: boolean = false;

  get camposList() {
    return this.$store.state.campana.camposList;
  }

  form = {
    name: "",
    region: undefined,
    date1: undefined,
    delivery: false,
    type: [],
    resource: "",
    desc: "",
  };

  values = [];
  wBoxing = true;
  wPath = true;
  openBox = false;

  async created(){
    await this.getPage();
  }

  async getPage(){
    this.loading = true;
    var campaignId = this.$route.params.id;
    this.campanaId = parseInt(campaignId);
    this.participacion.campanaId = this.campanaId;
    this.participacion.estadoParticipacionId = 5;
    await this.$store.dispatch({
      type: "campana/get",
      id: campaignId,
    })
    .then(result => {
      if(result){
        this.campana = result
      }
    });

    this.pageCamposRequest.campanaId = this.participacion.campanaId;
    await this.$store
      .dispatch({
        type: "campana/getAllCampos",
        data: this.pageCamposRequest,
      })

    this.pagePaticipacionDatoRequest.participacionId = this.participacion.id;
    await this.$store.dispatch({
      type: "participacionDato/getAll",
      data: this.pagePaticipacionDatoRequest,
    });
    this.participacionDato = this.$store.state.participacionDato.list;

    this.createParticipacionDatoIfNeeded()
    //this.createFormFields(this.campana.grupoTree);
    this.loading = false;
  }

  openClose() {
    this.openBox = !this.openBox;
  }

  get gridFields() {
    var rows = [[]];
    var cols = [];
    var spanPerRow = 0;

    for (var f of this.formFields) {
      cols.push(f);
      
      if(spanPerRow + f["span"] >= 24){
        rows.push(cols);
        cols = [];
        spanPerRow = 0; 
      }
      else{
        spanPerRow += f["span"];
      }
    }

    return rows;
  }

  createFormFields(campanaCamposTree : string){
    
    var campos = JSON.parse(campanaCamposTree);
    
    campos.forEach(group => {
      group.elements.forEach((child) => {
        let childElement = 
        {
          name: child["nombre"],
          order: child["order"], 
          span: child["tamanoCampo"] ? child["tamanoCampo"] : 24,
          value: ""
        }

        this.formFields.push(childElement);
      })
    })

    this.formFields.sort((a, b) => {
      return a.order - b.order;
    });
  }

sexo = [
    { label: "Hombre", key: "H" },
    { label: "Mujer", key: "M" },
  ];

inputSexo: any = null;

formInput(tipoDato) {
    switch (tipoDato) {
      case 0:
        return { comp: "base-input", type: "number", bind: "numerico" };
      case 1:
        return { comp: "base-input", type: "text", bind: "texto" };
      case 2:
        return {
          comp: "base-input",
          type: "number",
          bind: "decimal",
          step: "0.01",
        };
      case 3:
        return { comp: "base-checkbox", type: "checkbox", bind: "siNo" };
      case 4:
        return { comp: "base-date-picker", type: "date", bind: "fecha" };
      case 5:
        return { comp: "base-input", type: "text", bind: "url" };
      default:
        return "base-input";
    }
  }

  //si no está el dato del campo lo creamos
  createParticipacionDatoIfNeeded() {
    let result = this.camposList.filter(
      (o1) => !this.participacionDato.some((o2) => o1.id === o2.campoId)
    );
    if (result) {
      result.forEach((element) => {
        let participacionDato = new ParticipacionDato();
        participacionDato.campoId = element.id;
        participacionDato.participacionId = this.participacion.id;
        this.participacionDato.push(participacionDato);
      });
    }
  }

  makeRules(item, index) {
    this.rules[index] = "";
    if (item.listSelectedRules !== null) {
      const ruleCampo = JSON.parse(item.listSelectedRules);
      ruleCampo.forEach((rule) => {
        this.rules[index] = { ...this.rules[index], ...rule };
      });
    }
  }

participacionRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Nombre")),
      placeholder: this.L("Nombre"),
      trigger: "blur",
    },
    apellido1: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Apellido1")),
      placeholder: this.L("Apellido1"),
      trigger: "blur",
    },
    apellido2: {
      required: { min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Apellido2")),
      placeholder: this.L("Apellido2"),
      trigger: "blur",
    },
    email: {
      required: { required: true, email: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Email")),
      placeholder: this.L("Email"),
      trigger: "blur",
    },
    telefono: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Telefono")),
      placeholder: this.L("Telefono"),
      trigger: "blur",
    },
    fechaNacimiento: {
      required: { required: false },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("FechaNacimiento")
      ),
      placeholder: this.L("FechaNacimiento"),
      trigger: "blur",
      type: "date",
    },
    dni: {
      required: { required: false, regex: "^[xXyYzZ]\\d{7}[aA-zZ]$|^(\\d{8})([aA-zZ])$", min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("DNI")),
      placeholder: this.L("DNI/NIE"),
      trigger: "blur",
    },
    campana: {
      required: { filtrableDropdownRequired: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Campana")),
      placeholder: this.L("Campaña"),
      trigger: "blur",
    },
    ekon: {
      placeholder: this.L("Codigo Ekon")
    },
    sexo: {
      required: { filtrableDropdownRequired: false },
      placeholder: this.L("Sexo"),
      trigger: "blur",
    },
    estadoParticipacion: {
      required: { filtrableDropdownRequired: true },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("EstadoParticipacion")
      ),
      placeholder: this.L("EstadoParticipacion"),
      trigger: "blur",
    },
    subEstadoParticipacion: {
      required: { filtrableDropdownRequired: false },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("SubEstadoParticipacion")
      ),
      placeholder: this.L("SubEstadoParticipacion"),
      trigger: "blur",
    },
  };

  async onSubmit() {
    this.loading = true;
    this.participacion.participacionDato = this.participacionDato;
    await this.$store
      .dispatch({
        type: "participacion/create",
        data: this.participacion,
      }).then(async () => {
        //mensaje de OK/ERROR
        this.$message.success(
          "Participación grabada correctamente.",3
        );
      });

    this.inputSexo = null;
    this.participacion = new Participacion();
    this.participacion.campanaId = this.campanaId;

    this.pagePaticipacionDatoRequest.participacionId = this.participacion.id;
    await this.$store.dispatch({
      type: "participacionDato/getAll",
      data: this.pagePaticipacionDatoRequest,
    });

    this.participacionDato = this.$store.state.participacionDato.list;
    this.createParticipacionDatoIfNeeded()
    this.loading = false;
    this.participacion.estadoParticipacionId = 5;
    //await this.getPage()
  }
}
